<template>
  <div class="globalnode-style">
    <div class="bigbox">
      <div class="b11 center-center">
        <el-card class="box-card">
          <div class="b11-t1">{{$t('text.t141')}}</div>
          <div class="b11-t2">{{
            info1.level==-1?$t('text.t233'):
            info1.level==0?$t('text.t142'):
            info1.level==1?$t('text.t143'):
            info1.level==2?$t('text.t144'):
            info1.level==3?$t('text.t145'):
            info1.level==4?$t('text.t146'):
            info1.level==5?$t('text.t147'):
            info1.level==6?$t('text.t148'):
            info1.level==7?$t('text.t149'):
            info1.level==8?$t('text.t150'):
            info1.level==9?$t('text.t151'):''
            }}</div>
          <div class="b11-t3">
            {{$t('text.t152')}}{{info2.my}}{{$t('text.t153')}}，{{$t('text.t154')}}{{info2.push}}{{$t('text.t153')}}， {{$t('text.t155')}}{{info2.Interposition}}{{$t('text.t153')}}{{$t('text.t156')}}
          </div>
          <div class="b11-t4"></div>
          <div class="b11-t5 between-center">
            <div>{{$t('text.t249')}}</div>
            <div>{{info3.totalHashrate}}</div>
          </div>
          <template v-if="JSON.stringify(info1) != '{}' && info1.level!=-1 && info1.level!=0">
            <div class="b11-t6 between-center">
              <div>{{$t('text.t250')}}</div>
              <div>{{info3.nodeDirectpush}}</div>
            </div>
            <div class="b11-t6 between-center">
              <div>{{$t('text.t251')}}</div>
              <div>{{info3.nodepush}}</div>
            </div>
          </template>
          <template v-if="info1.level==9">
            <div class="b11-t6 between-center">
              <div>{{$t('text.t252')}}</div>
              <div>{{info3.cultivation}}</div>
            </div>
            <div class="b11-t6 between-center">
              <div>{{$t('text.t253')}}</div>
              <div>{{info3.push}}</div>
            </div>
          </template>
        </el-card>
      </div>
      <template v-if="JSON.stringify(info1) != '{}' && info1.level!=-1 && info1.level!=0">
        <div class="box7 between-center" @click="goAhead('/nodepush1')">
          <div>{{$t('text.t242')}}</div>
          <van-icon color="#000" name="arrow" size="0.32rem" />
        </div>
        <div class="box7 between-center" @click="goAhead('/nodepush2')">
          <div>{{$t('text.t243')}}</div>
          <van-icon color="#000" name="arrow" size="0.32rem" />
        </div>
        <template v-if="info1.level==9">
          <div class="box7 between-center" @click="goAhead('/nodepush3?type=1')">
            <div>{{$t('text.t254')}}</div>
            <van-icon color="#000" name="arrow" size="0.32rem" />
          </div>
          <div class="box7 between-center" @click="goAhead('/nodepush3?type=2')">
            <div>{{$t('text.t255')}}</div>
            <van-icon color="#000" name="arrow" size="0.32rem" />
          </div>
        </template>
      </template>
      <div class="box7 between-center" @click="goAhead('/nodedetail')">
        <div>{{$t('text.t162')}}</div>
        <van-icon color="#000" name="arrow" size="0.32rem" />
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  components: {
  },
  data() {
    return {
      info1:{},
      info2:{},
      info3:{},
      nodetext:'',
      isloading:false,
    };
  },
  computed: {
    ...mapState(["language"])
  },
  created(){
    this.getinfo()
  },
  mounted() {},
  methods: {
    getinfo(){
      this.$http.get('/index/gradeInfo').then(res=>{
        this.info1 = res.data
        this.info2 = res.data?.info
        this.info3 = res.data?.overview
      })
    },
    bindNode(){
      this.isloading = true
      this.$http.post('/index/bindGrade',{
        grade:this.nodetext,
        language:this.language
      }).then(res=>{
        this.nodetext = ''
        this.$toast(res.msg)
        this.getinfo()
        setTimeout(() => {
          this.isloading = false
        }, 1000);
      }).catch(()=>{
        // this.isloading = false
        setTimeout(() => {
          this.isloading = false
        }, 1000);
      })
    },
  },
};
</script>

<style lang="less">
.globalnode-style {
  .bigbox {
    width: 335px;
    margin: auto;
    .b11{
      padding: 13px 0 30px;
      .box-card{
        width: 350px;
        border-radius: 20px;
        .b11-t1{
          margin-bottom: 10px;
          font-size: 12px;
          font-weight: 600;
          color: #323232;
        }
        .b11-t2{
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #323232;
        }
        .b11-t3{
          margin-bottom: 15px;
          font-size: 10px;
          color: #909090;
        }
        .b11-t4{
          background: #E1E1E1;
          height: 1px;
          margin-bottom: 20px;
        }
        .b11-t5{
          margin-bottom: 24px;
          font-size: 18px;
          font-weight: 600;
          color: #323232;
        }
        .b11-t6{
          margin-bottom: 17px;
          font-size: 12px;
          font-weight: 600;
          color: #323232;
        }
      }
    }
    .box1 {
      border-bottom: 1px solid #e1e1e1;
      .b1-left {
        width: 50%;
        .b1l-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
          margin-bottom: 18px;
        }
        .b1l-t2 {
          font-size: 18px;
          font-weight: 500;
          color: #323232;
          margin-bottom: 10px;
        }
        .b1l-t3 {
          font-size: 10px;
          font-weight: 300;
          color: #909090;
          padding-bottom: 9px;
        }
      }
      .b1-right {
        width: 49%;
        border-left: 1px solid #e1e1e1;
        text-align: center;
        .b1-right-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
          margin-bottom: 25px;
        }
        .b1-right-t2 {
          font-size: 18px;
          font-weight: 500;
          color: #323232;
          margin-bottom: 25px;
        }
      }
    }
    .box2 {
      border-bottom: 1px solid #e1e1e1;
      padding: 25px 0 6px 2px;
      font-size: 12px;
      font-weight: 500;
      color: #323232;
    }
    .box3 {
      border-bottom: 1px solid #e1e1e1;
      padding: 16px 0 22px 0;
      font-size: 12px;
      font-weight: 500;
      color: #323232;
      text-align: center;
    }
    .box4 {
      font-size: 12px;
      font-weight: 500;
      color: #fefefe;
      width: 200px;
      height: 30px;
      border-radius: 10px;
      margin: 10px auto 18px;
    }
    .box4-ac {
      background: #323232;
    }
    .box4-no{
      background: #909090;
    }
    .box5 {
      padding: 6px 0;
      border-bottom: 1px solid #e1e1e1;
      font-size: 12px;
      font-weight: 500;
      color: #323232;
    }
    .box6 {
      padding: 15px 0;
      border-bottom: 1px solid #e1e1e1;
      font-size: 15px;
      font-weight: 500;
      color: #323232;
      text-align: center;
      margin-bottom: 25px;
    }
    .box7{
      padding: 15px 20px;
      // border-bottom: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1;
      font-size: 15px;
      font-weight: 500;
      color: #323232;
      box-sizing: border-box;
    }
    .box7:last-of-type{
      border-bottom: 1px solid #e1e1e1;
    }
    .box8{
      padding: 10px 15px;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 25px;
      .b8-inp{
        height: 24px;
        flex: 1;
        font-size: 15px;
        color: #323232;
        border: none;
      }
      .b8-btn{
        font-size: 12px;
        font-weight: 500;
        color: #fefefe;
        width: 60px;
        height: 30px;
        border-radius: 10px;
        background: #323232;
      }
    }
  }
}
</style>